import {
  defineComponent,
  ref,
  Transition,
  FunctionalComponent,
  withModifiers,
} from 'vue'
import {
  until,
  promiseTimeout,
  TransitionPresets,
  useTransition,
} from '@vueuse/core'
import * as styles from '@/styles/join.css'
import { onBeforeRouteLeave, useRoute, useRouter } from 'vue-router'
import {
  joinGame,
  updateGame,
  getSmallestAvailableTeam,
  joinTeam,
  assertStatusCode,
} from '@/utils/api'
import { useGame } from '@/utils/useGame'
import { usePlayer } from '@/utils/usePlayer'
import { usePusher } from '@/utils/usePusher'
import { Input } from '@/components/atoms/input/Input'
import { GameButton } from '@/components/atoms/gameButton/GameButton'
import { ConfettiLogo } from '@/components/molecules/confettiLogo/ConfettiLogo'
import { Fade } from '@/components/atoms/fade/Fade'
import { SvgBox } from '@/components/atoms/svgBox/SvgBox'
import { useLocalParticipant } from '@/utils/streaming/useLocalParticipant'
import { onceSet } from '@/utils/onceSet'
import { useDaily } from '@/utils/streaming/useDaily'
import { CircleLoader } from '@/components/atoms/circleLoader/CircleLoader'
import { Tag } from '@/components/molecules/tag/Tag'
import { MediaErrorModal } from '@/modules/media/MediaErrorModal'
import { FadeImage } from '@/components/atoms/fade/FadeImage'
import { LocalTile } from '@/modules/join/LocalTile'
import { useSupportedBrowser } from '@/utils/streaming/useSupportedBrowser'
import { omit } from 'lodash'

const FadeFromBottom: FunctionalComponent = (_, { slots }) => (
  <Transition
    appear
    enter-active-class={styles.activeFadeTransform}
    leave-active-class={styles.activeFadeTransform}
    enter-from-class={styles.initialFadeTransform}
    leave-to-class={styles.initialFadeTransform}
  >
    {slots.default?.()}
  </Transition>
)

const FadeFromBottomWithDelay: FunctionalComponent = (_, { slots }) => (
  <Transition
    appear
    enter-active-class={styles.activeFadeTransformDelay}
    leave-active-class={styles.activeFadeTransformDelay}
    enter-from-class={styles.initialFadeTransform}
    leave-to-class={styles.initialFadeTransform}
  >
    {slots.default?.()}
  </Transition>
)

const FadeWithDelay: FunctionalComponent = (_, { slots }) => (
  <Transition
    appear
    enter-active-class={styles.activeFade}
    leave-active-class={styles.activeFade}
    enter-from-class={styles.initialFade}
    leave-to-class={styles.initialFade}
  >
    {slots.default?.()}
  </Transition>
)

export const GameJoin = defineComponent({
  name: 'GameJoin',
  setup() {
    const { params, query } = useRoute()
    const router = useRouter()
    const gameId = params.gameId as string
    const { userId } = usePusher()
    const {
      game,
      setGame,
      introCover,
      playersWithoutHost,
      hostCover,
      playerCover,
    } = useGame(gameId)
    const { playerId, setPlayerId } = usePlayer(gameId)
    const playerName = ref<string>()
    const gamePassword = ref<string>()
    const isJoining = ref(false)
    const hasPasswordError = ref(false)
    const isGameWithVideo = ref<boolean | null>(null)
    const isGameWithVideoLoading = ref(true)
    const isGameWithVideoLoaded = ref(false)
    const { supported } = useSupportedBrowser()
    const hostCode = query.host as string | undefined

    if (hostCode)
      router.replace({
        name: 'join',
        params: { gameId },
        query: omit(query, 'host'),
      })

    // redirect to lobby if user exist
    onceSet(playerId, () => {
      if (!isJoining.value) {
        router.replace({
          name: 'lobby',
          params: { gameId },
        })
      }
    })

    // This hack is needed because onBeforeRouteLeave must be set in top setup
    // and leaveCall is available inside a closure. The better soultion whould be to refactor this file to
    // have JoinWithRoom and JoinWithoutRoom (same as in lobby), but out of scope for now @TODO
    let leaveCallFn: () => Promise<void>

    onceSet(game, async (game) => {
      isGameWithVideo.value = Boolean(game.roomName)

      if (game.roomName && supported) {
        const { createDailyObjIfNotExist, callObj } = useDaily(game.roomName)
        const { startCall, local, leaveCall } = useLocalParticipant(
          game.roomName,
        )
        leaveCallFn = leaveCall
        onceSet(hostCode ? hostCover : playerCover, async (backgroundImage) => {
          createDailyObjIfNotExist({
            subscribeToTracksAutomatically: false,
            backgroundImage,
            isLobby: false,
          })
          await until(callObj).not.toBeNull()
          await startCall()
          const loaderTimeout = promiseTimeout(1600)
          await until(local).not.toBeNull()
          isGameWithVideoLoaded.value = true
          await loaderTimeout
          isGameWithVideoLoading.value = false
          transitionSource.value = 1
        })
      }
    })

    onBeforeRouteLeave(async (_to, _from, next) => {
      if (!leaveCallFn) return next()
      await leaveCallFn()
      next()
    })

    const transitionSource = ref(0)
    const transitionOutput = useTransition(transitionSource, {
      duration: 400,
      transition: TransitionPresets.ease,
    })

    const join = async ({ withPassword }: { withPassword: boolean }) => {
      if (!playerName.value || (withPassword && !gamePassword.value)) return

      try {
        isJoining.value = true
        hasPasswordError.value = false
        let randomizeTeam
        let routerProps

        if (game.value?.isRandomize && game.value.isStarted) {
          randomizeTeam = await getSmallestAvailableTeam(gameId)
        }

        const { playerId, playerKey } = await joinGame({
          gameId,
          name: playerName.value,
          gamePassword: gamePassword.value,
          pusherId: userId.value,
          hostCode,
        })

        setPlayerId(playerId)

        if (randomizeTeam) {
          await joinTeam({
            teamId: randomizeTeam.id,
            playerId,
            playerKey,
            pusherId: userId.value,
          })

          routerProps = {
            name: 'team',
            params: { gameId, teamId: randomizeTeam.id },
          }
        } else {
          routerProps = {
            name: 'lobby',
            params: { gameId },
          }
        }

        await updateGame(gameId).then(setGame)

        // no need to reset isJoining for route leave
        await router.push(routerProps)
      } catch (err) {
        isJoining.value = false
        assertStatusCode(err as Error, 401)
        hasPasswordError.value = true
      }
    }

    const renderTitle = () => (
      <div class={styles.titlesBox}>
        <div class={styles.veqTitle}>VIRTUAL ESCAPE QUEST</div>
        {hostCode && <div class={styles.questTitle}>HOSTING:</div>}
        <div class={styles.questTitle}>{game.value?.title}</div>
      </div>
    )

    const renderActionBox = ({
      showPasswordInput,
    }: {
      showPasswordInput: boolean
    }) => (
      <div class={styles.actionBox}>
        <form
          class={styles.actionBoxForm}
          onSubmit={withModifiers(
            () => join({ withPassword: showPasswordInput }),
            ['prevent'],
          )}
          autocomplete="off"
        >
          <div class={styles.actionBoxInputs}>
            <Input
              placeholder="Your Name"
              value={playerName.value}
              onInput={(value) => (playerName.value = value)}
              autocomplete="cc-csc"
              name="join"
            />
            {showPasswordInput && (
              <Input
                type="password"
                placeholder="Password"
                value={gamePassword.value}
                onInput={(value) => (gamePassword.value = value)}
                autocomplete="cc-csc"
                name="password"
              />
            )}
          </div>
          <GameButton
            text="Join Quest"
            rgbColor={game.value?.color}
            loading={isJoining.value}
            size="medium"
            disabled={
              !playerName.value || (showPasswordInput && !gamePassword.value)
            }
          />
        </form>
        <div class={styles.playerTag}>
          {playersWithoutHost.value && (
            <Fade appear delay>
              <Tag
                color="white"
                title={
                  playersWithoutHost.value.length > 0
                    ? `${playersWithoutHost.value.length} ${
                        playersWithoutHost.value.length === 1
                          ? 'Player'
                          : 'Players'
                      } inside`
                    : `You'll be the first to join`
                }
                maxWidth="100%"
              />
            </Fade>
          )}
          {hasPasswordError.value && (
            <Fade appear>
              <div class={styles.passwordError}>Wrong Password</div>
            </Fade>
          )}
        </div>
      </div>
    )

    const renderContent = () => {
      if (isGameWithVideo.value === null) return

      const title = renderTitle()

      if (isGameWithVideo.value) {
        return (
          <>
            <div class={styles.contentLeft}>
              {isGameWithVideoLoading.value ? (
                <FadeFromBottomWithDelay>{title}</FadeFromBottomWithDelay>
              ) : (
                <FadeFromBottom>
                  <div>
                    {title}
                    {renderActionBox({ showPasswordInput: true })}
                  </div>
                </FadeFromBottom>
              )}
            </div>
            <div>
              {isGameWithVideoLoading.value ? (
                <div class={styles.loaderBox}>
                  <FadeWithDelay>
                    <CircleLoader class={styles.loader} />
                  </FadeWithDelay>
                </div>
              ) : isGameWithVideoLoaded.value && game.value?.roomName ? (
                <FadeFromBottom>
                  <div class={styles.waitingBoxWrapper}>
                    <SvgBox
                      class={styles.waitingBoxSvg}
                      isSmall={false}
                      bgType="dark"
                    >
                      <LocalTile roomName={game.value.roomName} />
                    </SvgBox>
                  </div>
                </FadeFromBottom>
              ) : null}
            </div>
            {game.value?.roomName && (
              <MediaErrorModal roomName={game.value.roomName} />
            )}
          </>
        )
      }

      return (
        <>
          <div class={styles.contentLeft}>
            <FadeFromBottomWithDelay>
              <div>
                {title}
                {renderActionBox({ showPasswordInput: false })}
              </div>
            </FadeFromBottomWithDelay>
          </div>
          <div />
        </>
      )
    }

    return () => (
      <div class={styles.root}>
        <FadeImage src={introCover.value} />
        <div class={styles.header}>
          <ConfettiLogo class={styles.headerBoxSize} />
          {game.value?.company.name && (
            <Fade appear>
              <SvgBox class={styles.headerBoxSize} bgType="dark">
                <div class={styles.companyName}>{game.value.company.name}</div>
              </SvgBox>
            </Fade>
          )}
        </div>
        <div class={styles.content}>{renderContent()}</div>
        <div
          class={styles.bluredBg}
          style={{
            opacity: transitionOutput.value,
          }}
        />
      </div>
    )
  },
})

export default GameJoin
